/* ==============
  Email
===================*/
.email-leftbar {
  width: 236px;
  float: left;
  padding: 20px;
  border-radius: 5px;
}

.email-rightbar {
  margin-left: 260px;
}

.chat-user-box {
  p.user-title {
    color: var(--#{$prefix}body-color);
    font-weight: 600;
  }

  p {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .email-leftbar {
    float: none;
    width: 100%;
  }

  .email-rightbar {
    margin: 0;
  }
}


.mail-list {
  a {
    display: block;
    color: $gray-600;
    line-height: 24px;
    padding: 5px;

    &.active {
      color: $danger;
      font-weight: 500;
    }
  }
}

.message-list {
  display: block;
  padding-left: 0;

  li {
    position: relative;
    display: block;
    height: 75px;
    cursor: default;
    transition-duration: .3s;

    a {
      color: #a5a6ad !important;
    }

    &:hover {
      background: var(--#{$prefix}tertiary-bg);
      transition-duration: .05s;
    }

    .col-mail {
      float: left;
      position: relative;
    }

    .col-mail-1 {
      width: 320px;

      .star-toggle,
      .checkbox-wrapper-mail,
      .dot {
        display: block;
        float: left;
      }

      .dot {
        border: 4px solid transparent;
        border-radius: 100px;
        margin: 22px 26px 0;
        height: 0;
        width: 0;
        line-height: 0;
        font-size: 0;
      }

      .checkbox-wrapper-mail {
        margin: 28px 10px 0 20px;
      }

      .star-toggle {
        margin-top: 31px;
        margin-left: 5px;
      }

      .title {
        position: absolute;
        top: 8px;
        left: 110px;
        right: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-bottom: 0;
      }
    }

    .col-mail-2 {
      position: absolute;
      top: 0;
      left: 320px;
      right: 0;
      bottom: 0;

      .subject,
      .date {
        position: absolute;
        top: 0;
      }

      .subject {
        left: 0;
        right: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .date {
        right: 0;
        width: 170px;
        padding-left: 70px;
        line-height: 75px;
      }
    }

    &.active,
    &.active:hover {
      box-shadow: inset 3px 0 0 $primary;
    }


    &.unread {
      background-color: var(--#{$prefix}tertiary-bg);
      font-weight: 500;
      color: var(--#{$prefix}body-color);

      a {
        color:var(--#{$prefix}body-color);
        font-weight: 500;
      }
    }
  }


  .checkbox-wrapper-mail {
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    display: inline-block;
    box-shadow: inset 0 0 0 1px $gray-400;
    border-radius: 1px;

    input {
      opacity: 0;
      cursor: pointer;
    }

    input:checked~label {
      opacity: 1;
    }

    label {
      position: absolute;
      height: 20px;
      width: 20px;
      left: 0;
      cursor: pointer;
      opacity: 0;
      margin-bottom: 0;
      transition-duration: .05s;
      top: 0;

      &:before {
        content: "\F012C";
        font-family: "Material Design Icons";
        top: 0;
        height: 20px;
        color: darken($dark, 5%);
        width: 20px;
        position: absolute;
        margin-top: -16px;
        left: 4px;
        font-size: 13px;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .message-list li .col-mail-1 {
    width: 200px;
  }
}


// email read

.email-inbox {
  position: relative;

  .form-control,
  .form-control:focus {
    font-size: 13px;
    height: 40px;
    padding-left: 34px;
    padding-right: 12px;
    margin-right: 16px;
    box-shadow: none;
    border: none;
  }

  .email-icon {
    position: absolute;
    top: 10px;
    left: 11px;
  }
}

.email-img-overlay {
  .email-overlay {
    i {
      background: $primary;
      color: $white;
      font-size: 20px;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      line-height: 40px;
      transition: all 0.5s;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      left: 0;
      right: 0px;
      margin: 0 auto;
      opacity: 0;
    }
  }

  &:hover {
    img {
      opacity: 0.2;
    }

    .email-overlay {
      i {
        opacity: 1
      }
    }
  }
}