// 
// timeline.scss
//
/************** vertical timeline **************/ 
.cd-container {
    width: 90%;
    max-width: 1170px;
    margin: 0 auto;
}

.main-timeline {
    overflow: hidden;
    position: relative;

    &:before {
        content: "";
        width: 3px;
        height: 100%;
        background: var(--#{$prefix}border-color);
        position: absolute;
        top: 0;
        left: 50%;
    }

    .timeline {
        padding-right: 30px;
        position: relative;
    }

    .timeline-icon {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: var(--#{$prefix}tertiary-bg);
        border: 2px solid var(--#{$prefix}border-color);
        box-sizing: content-box;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: -4px;

        &:before {
            content: "";
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: $primary;
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
    }

    .year {
        display: inline-block;
        padding: 8px 20px;
        margin: 0;
        font-size: 14px;
        color: $white;
        background: $primary;
        text-align: center;
        position: absolute;
        top: 50%;
        right: 35%;
        transform: translateY(-50%);

        &:before {
            content: "";
            border-right: 18px solid $primary;
            border-top: 18px solid transparent;
            border-bottom: 18px solid transparent;
            position: absolute;
            top: 0;
            left: -16px;
        }
    }

    .timeline-content {
        width: 46.5%;
        padding: 43px 50px;
        margin: 0 20px 0 0;
        background: var(--#{$prefix}tertiary-bg);
        position: relative;

        &:after {
            content: "";
            border-left: 20px solid var(--#{$prefix}border-color);
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
            position: absolute;
            top: 50%;
            right: -20px;
            transform: translateY(-50%);
        }
    }

    .title {
        float: left;
        font-size: 18px;
        margin: 0 20px 20px 0;
    }

    .description {
        font-size: 14px;
        line-height: 24px;
        margin: 0;
        clear: both;
    }
}

.main-timeline .timeline:before,
.main-timeline .timeline:after {
    content: "";
    display: block;
    width: 100%;
    clear: both;
}

.main-timeline .timeline:first-child:before,
.main-timeline .timeline:last-child:before {
    content: "";
    width: 13px;
    height: 13px;
    border-radius: 50%;
    border: 2px solid var(--#{$prefix}border-color);
    background: var(--#{$prefix}tertiary-bg);
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    right: -3px;
}

.main-timeline {
    .timeline {
        &:last-child {
            &:before {
                top: auto;
                bottom: 0;
            }
        }

        &:nth-child(2n) {
            padding: 0 0 0 30px;

            .year {
                right: auto;
                left: 35%;

                &:before {
                    border: 18px solid transparent;
                    border-right: none;
                    border-left: 18px solid $primary;
                    left: auto;
                    right: -18px;
                }
            }

            .timeline-content {
                float: right;
                margin: 0 0 0 20px;

                &:after {
                    border-left: none;
                    border-right: 20px solid var(--#{$prefix}border-color);
                    right: auto;
                    left: -20px;
                }
            }
        }
    }
}



@media only screen and (max-width: 1200px) {
    .main-timeline {
        .year {
            right: 30%;
        }
    }

    .main-timeline .timeline:nth-child(2n) .year {
        left: 30%;
    }
}

@media only screen and (max-width: 990px) {
    .main-timeline {
        .year {
            right: 25%;
        }
    }

    .main-timeline .timeline:nth-child(2n) .year {
        left: 25%;
    }
}

@media only screen and (max-width: 767px) {
    .main-timeline {
        &:before {
            left: 10px;
        }
    }

    .main-timeline {
        .timeline {
            padding: 0 0 0 30px;
            margin-bottom: 20px;
        }
    }

    .main-timeline .timeline:last-child {
        margin-bottom: 0;
    }

    .main-timeline .timeline:first-child:before,
    .main-timeline .timeline:last-child:before {
        display: none;
    }

    .main-timeline .timeline-icon {
        margin: 0;
        position: absolute;
        top: 7px;
        left: 0;
    }

    .main-timeline .year,
    .main-timeline .timeline:nth-child(2n) .year {
        display: block;
        font-weight: bold;
        margin: 0 0 32px 30px;
        z-index: 1;
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        transform: none;
    }

    .main-timeline .timeline:nth-child(2n) .year:before {
        border-left: none;
        border-right: 18px solid $primary;
        right: auto;
        left: -18px;
    }

    .main-timeline .timeline-content {
        padding: 20px;
    }

    .main-timeline .timeline-content,
    .main-timeline .timeline:nth-child(2n) .timeline-content {
        width: auto;
        float: none;
        margin: 0 0 0 30px;
    }

    .main-timeline .timeline-content:after,
    .main-timeline .timeline:nth-child(2n) .timeline-content:after {
        border: none;
        border-bottom: 20px solid$light;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        top: -20px;
        left: 50%;
        right: auto;
        transform: translateX(-50%);
    }
}

@media only screen and (max-width: 480px) {
    .main-timeline {
        .title {
            float: none;
        }
    }

    .main-timeline .year,
    .main-timeline .timeline:nth-child(2n) .year {
        margin-left: 20px;
    }

    .main-timeline .timeline-content,
    .main-timeline .timeline:nth-child(2n) .timeline-content {
        margin-left: 10px;
    }
}