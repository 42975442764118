/* ==============
  Alertify
===================*/

.alertify,
.alertify-logs {
    z-index: 9999;
}

.alertify {
    input {
        border: 2px solid var(--#{$prefix}border-color);
    }
}

.alertify-logs>.success {
    background-color: $success;
    color: $white;
}

.alertify-logs>.error {
    background-color: $danger;
    color: $white;
}

.alertify-logs>*,
.alertify-logs>.default {
    background-color: $primary;
}